import React from 'react';
import { Provider, observer } from 'mobx-react';
import TodoApp from './components/UserPortal/TodoApp/TodoApp';
import TimerApp from './components/UserPortal/TimerApp/TimerApp';
import FlashcardsApp from './components/UserPortal/FlashcardsApp/FlashcardsApp';
import { ThemeProvider } from '@mui/material/styles';
import './App.scss';
import theme from './theme';

function App() {
  return (
    <Provider>
      <ThemeProvider theme={theme}> {/* Wrap your application with ThemeProvider */}
        <div className='App'>
          <div className='App-Container'>
            <TodoApp />
            <TimerApp />
            {/* <FlashcardsApp /> */}
            {/* <div className='To-Disclose'><h1></h1></div> */}
          </div>
        </div>
      </ThemeProvider>
    </Provider>
  )
}

export default observer(App);
