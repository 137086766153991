import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#FF9300', // Matches $accent-color-orange
      dark: '#cc7a00', // Matches $accent-color-orange-dark
      light: '#ffab40', // Matches $accent-color-orange-light
    },
    secondary: {
      main: '#0072C6', // Matches $accent-color-blue
      dark: '#005999', // Matches $accent-color-blue-dark
      light: '#3388ff', // Matches $accent-color-blue-light
    },
    background: {
      default: '#E2E2E2', // Matches $background-color
      paper: '#E5ECF2', // Matches $base-color-dark
    },
    text: {
      primary: '#333333', // Matches $text-color
      secondary: '#A9A9A9', // Matches $text-color-dark
    },
    error: {
      main: '#FF0000',
    },
    success: {
      main: '#008000',
    },
    warning: {
      main: '#FFA500',
    },
    info: {
      main: '#0000FF',
    },
  },
  typography: {
    fontFamily: "'Open Sans', sans-serif", // Matches $font-family-base
  },
});

export default theme;
