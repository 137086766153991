import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import timerStore from '../../../store/timerStore';
import { Box, Button, Paper, Typography } from '@mui/material';
import useStyles from './TimerStyle.js';

import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import HistoryIcon from '@mui/icons-material/History';

// import RestoreIcon from '@mui/icons-material/Restore';
// haven't decided yet, but it's possible I'd like to have a speed dial option
// to change the times for work/break sessions of the timer
// also I would like to have a chip stating whether it's work or break time
// and sounds to play when the timer starts/stops
// later on after the MVC I would like to make it possible for users to modify
// the sounds for the timer (short 3 second sound for work or break session announcement)

const TimerApp = observer(() => {
  const { timer } = timerStore;
  const classes = useStyles();

  useEffect(() => {
    let timerID: string | number | NodeJS.Timeout | undefined;
    if (timer.isRunning) {
      timerID = setInterval(() => {
        timer.decrementTime();
      }, 1000);
    }
    return () => clearInterval(timerID); // clean up the interval on unmount
  }, [timer.isRunning]);

  const minutes = Math.floor(timer.time / 60);
  const seconds = timer.time % 60;

  return (
    <Paper className={classes.sessionTimer}>
      <Box className={classes.timerSettingsBox}>
        {/* Sto sakam da ima tuka? */}
        {/* Ikona za mestenje na work time, i za pauza? ili speed dial */}
        {/* button with icon inside */}
        
      </Box>
      <Typography variant="h4" gutterBottom className={classes.timerText}>
        {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
      </Typography>
      <Box className={classes.timerButtonBox}>
        <Button
          variant="contained"
          className={classes.timerButton}
          onClick={() => timer.reset()}
        >
          <HistoryIcon />
        </Button>
        <Button
          variant="contained"
          className={classes.timerButton}
          onClick={() => timer.start()}
        >
          Start
        </Button>
        <Button
          variant="contained"
          className={classes.timerButton}
          onClick={() => timer.stop()}
        >
          Stop
        </Button>
      </Box>
    </Paper>
  );
});

export default TimerApp;
