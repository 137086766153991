
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => ({
  sessionTimer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    boxShadow: '0px 0px 15px rgba(22, 22, 29, 0.337254902) !important',
  },
  timerButton: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '.5em',
    alignSelf: 'flex-end',
    border: 'none',
    borderRadius: '5px',
    padding: '.5em 1em',
    fontSize: 'medium',
    backgroundColor: '#F2F2F2 !important', // Matches $base-color
    color: theme.palette.text.primary, // Matches $text-color
    cursor: 'pointer',
    transition: 'all 0.2s ease-in-out',
    boxShadow: '5px 5px 5px 0 rgba(22,22,29,.1)',
    
    '&:hover': {
      backgroundColor: '#fff', // white
    },
  }, // empty rule needed for & $ClearCompleted to work
  timerText: {
    textShadow: '5px 5px 3px rgba(22, 22, 29, 0.1)'
  },
  timerButtonBox: {
    display: 'flex', 
    gap: 5
  },
  timerSettingsBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 2    
  },
  timerSettingsButton: {
    // let's wait to see if we need this
  }
}));

export default useStyles;
