import { makeAutoObservable } from "mobx";
import Todo from "../models/Todo/todoModel";

class TodoStore {
    todos: Array<Todo> = [];
    charLimit = 75;

    add = (todo: Todo) => {
        if (todo.title) {
            todo.id = this.todos.length + 1;
            todo.subTodos = [];
            this.todos.push(todo);
        }
    }

    addSubTodo = (todoId: number, subTodo: Todo) => {
        const parentTodo = this.todos.find(todo => todo.id === todoId);
        if (parentTodo) {
            subTodo.id = parentTodo.subTodos.length + 1;
            parentTodo.subTodos.push(subTodo);
        }
    }

    toggle = (index: number) => {
        this.todos[index].completed = !this.todos[index].completed;
    }

    clearCompleted = () => {
        this.todos = this.todos.filter(todo => !todo.completed);
    }

    clearAll = () => {
        this.todos = [];
    }

    toggleEditing = (todoId: number) => {
        this.todos.forEach(todo => {
            if (todo.id === todoId) {
                todo.editing = !todo.editing;
                console.log(`Task ${todoId} is now ${todo.editing ? "editing" : "not editing"}`);
            }
        })
    }

    updateTitle = (todoId: number, newTitle: string) => {
        this.todos.forEach(todo => {
            if (todo.id === todoId) {
                todo.title = newTitle;
            }
        })
    }

    delete = (todoId: number) => {
        this.todos = this.todos.filter(todo => todo.id !== todoId);
    }

    deleteSubTodo = (todoId: number, subTodoId: number) => {
        const parentTodo = this.todos.find(todo => todo.id === todoId);
        if (parentTodo) {
            parentTodo.subTodos = parentTodo.subTodos.filter(subTodo => subTodo.id !== subTodoId);
        }
    }

    constructor() {
        makeAutoObservable(this);
    }
}

const todoStore = new TodoStore();
export default todoStore;
