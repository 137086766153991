import React, { useState } from 'react';
import { observer } from 'mobx-react';
import todoStore from '../../../store/todoStore';
import './TodoApp.scss';
import { MdEditNote, MdDeleteForever, MdCheck } from "react-icons/md";

const TodoApp = () => {
  const [todo, setTodo] = useState('')

  return (
    <div className='To-Do-List'>
      <div className='Task-Input-Container'>
        <input
          className='Task-Input'
          autoFocus
          type='text'
          placeholder='Enter a task'
          value={todo}
          maxLength={todoStore.charLimit ? todoStore.charLimit : 75}
          onChange={(e) => setTodo(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              todoStore.add({
                id: todoStore.todos.length + 1 || 0,
                title: todo,
                completed: false,
                editing: false,
                subTodos: []
              })
              setTodo('')
            }
          }}
        />
        <p className='Task-Input-Counter'>
          {todoStore.charLimit && todoStore.charLimit - todo.length}
        </p>
      </div>
      <div className='Tasks-List'>
        {todoStore.todos.map((todoItem, index) => (
          // action buttons
          <div
            className='Task-Row'
            key={`todo-item-${todoItem.id}`}
          >
            <MdCheck
              size={26}
              className={todoItem.completed ? 'Icon-Button' : 'Icon-Button-Undone'}
              onClick={() => todoStore.toggle(index)}
            />
            <MdDeleteForever
              size={26}
              className='Icon-Button'
              onClick={() => todoStore.delete(todoItem.id)}
            />
            <MdEditNote
              size={26}
              className='Icon-Button'
              onClick={() => todoStore.toggleEditing(todoItem.id)}
            />
            {
              todoItem.editing ? (
                <input
                  className='Task-Edit-Input'
                  autoFocus
                  type='text'
                  value={todoItem.title}
                  maxLength={todoStore.charLimit ? todoStore.charLimit : 75}
                  onChange={(e) => todoStore.updateTitle(todoItem.id, e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      todoStore.toggleEditing(todoItem.id)
                    }
                  }}
                />
              ):
              (
                <p
                  className={`Pointer Non-Selectable ${todoItem.completed ? 'Task-Completed' : 'Task'}`}
                  onClick={() => todoStore.toggle(index)}
                >
                  {todoItem.title}
                </p>
              )
            }
            
          </div>
        ))}
      </div>
      <div className='Action-Buttons'>        
        <button
          className='Clear-Completed'
          onClick={() => {
            todoStore.clearCompleted();
          }}
          disabled={!todoStore.todos || todoStore.todos.every((todoItem) => todoItem.completed === false)}
        >
          Clear Completed
        </button>
        <button
          className='Clear-Completed'
          onClick={() => {
            todoStore.clearAll();
          }}
        >
          Clear All
        </button>
      </div>
    </div>
  )
}

export default observer(TodoApp)
