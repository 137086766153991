import { makeAutoObservable } from "mobx";
import TimerModel from "../models/Timer/timerModel";

class TimerStore {
  timer = new TimerModel(1, 1500, "work", 0);

  constructor() {
    makeAutoObservable(this)
  }
}

export default new TimerStore();
